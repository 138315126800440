import { ApiResponse, Crud } from '@amirsavand/ngx-common';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MemberInvite } from '@app/shared/interfaces/member-invite';
import { ProfileMember } from '@app/shared/interfaces/profile-member';
import { Tenant } from '@app/shared/interfaces/tenant';
import {
  MemberOnline,
  MemberPublic,
  Message,
  MessageApi,
  MessageAttachmentKind,
  RoomApi,
  RoomMemberApi,
  RoomMemberFullApi,
} from '@app/tenant';
import { environment } from '@environments/environment';
import { faFileZipper, faImage } from '@fortawesome/free-regular-svg-icons';
import { faFile, faFilm, faMusic } from '@fortawesome/free-solid-svg-icons';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import { ApiService as ApiServiceBase, Configs, ERROR_IGNORE } from '@SavandBros/savandbros-ngx-common';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends ApiServiceBase {
  /** Cached configs data.  */
  public readonly configs = new BehaviorSubject<Configs | null>(null);

  /** Base URL of API endpoints. */
  public readonly base = `${environment.api}v1/`;

  /** region Tenant APIs */

  public readonly tenant = new Crud<Tenant>({
    injector: this.injector,
    name: 'tenants/TID/tenant/tenants',
  });
  public readonly member = new Crud<ProfileMember>({
    injector: this.injector,
    name: 'tenants/TID/tenant/members',
  });
  public readonly memberRoom = this.member.getAction<RoomMemberApi, RoomMemberApi[]>('rooms', true);
  public readonly memberPing = this.member.getAction<RoomMemberApi, RoomMemberApi[]>('ping', true, {
    getContext: (): HttpContext => new HttpContext().set(NGX_LOADING_BAR_IGNORED, true).set(ERROR_IGNORE, true),
  });
  public readonly memberPublic = this.member.getAction<MemberPublic, MemberPublic[]>('public', false);
  public readonly memberOnline = this.member.getAction<MemberOnline, MemberOnline[]>('public/online', false);
  public readonly memberInvite = new Crud<MemberInvite>({
    injector: this.injector,
    name: 'tenants/TID/tenant/member-invites',
  });
  public readonly memberInviteResend = this.memberInvite.getAction<void, void>('resend', true);

  /** endregion */

  /** region Chat APIs */

  public readonly message = new Crud<MessageApi>({
    injector: this.injector,
    name: 'tenants/TID/chat/messages',
    mapItem: (data: Message): Message => {
      // Generate _kind and _icon based on type of attachments.
      if (data.attachments) {
        for (const attachment of data.attachments) {
          const ext: string = attachment.file.ext.toLowerCase();
          if (attachment.file.is_image) {
            attachment._kind = MessageAttachmentKind.IMAGE;
            attachment._icon = faImage;
          } else if (ext.includes('audio')) {
            attachment._kind = MessageAttachmentKind.AUDIO;
            attachment._icon = faMusic;
          } else if (ext.includes('video')) {
            attachment._kind = MessageAttachmentKind.VIDEO;
            attachment._icon = faFilm;
          } else if (ext.includes('compressed') || ext.includes('zip')) {
            attachment._kind = MessageAttachmentKind.COMPRESSED;
            attachment._icon = faFileZipper;
          } else {
            attachment._kind = MessageAttachmentKind.OTHER;
            attachment._icon = faFile;
          }
        }
      }
      return data;
    },
  });
  public readonly messageCount = this.message.getAction<void, { count: number }>('count', false);
  public readonly messageReact = this.message.getAction<{ reaction_code: string }>('react', true);
  public readonly messageStar = this.message.getAction<void>('star', true);
  public readonly messageUnstar = this.message.getAction<void>('unstar', true);
  public readonly messageDraft = new Crud<MessageApi>({
    injector: this.injector,
    name: 'tenants/TID/chat/draft-messages',
  });
  public readonly room = new Crud<RoomApi>({
    injector: this.injector,
    name: 'tenants/TID/chat/rooms',
  });
  public readonly roomMarkBulkAsRead = this.room.getAction<{ messages: MessageApi['id'][] }>('mark-bulk-as-read', true);
  public readonly roomMarkAllAsRead = this.room.getAction<void>('mark-all-as-read', true);
  public readonly roomMember = this.room.getAction<RoomMemberFullApi, ApiResponse<RoomMemberFullApi>>('members', true);
  public readonly roomJoin = this.room.getAction<void, void>('join', true);
  public readonly roomTyping = this.room.getAction<{ message?: MessageApi['id'] }, void>('is-typing', true, {
    getContext: (): HttpContext => new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
  });
  public readonly roomCreateDirect = this.room.getAction<{ participant: number } & RoomApi, void>(
    'create-direct',
    false,
  );

  /** endregion */

  constructor(
    private readonly http: HttpClient,
    private readonly injector: Injector,
  ) {
    super();
  }

  /** Update the employee status. */
  public memberStatusToggle(employee: ProfileMember['id'], status: ProfileMember['is_active']): Observable<void> {
    return this.http.patch<void>(`${this.base}tenants/TID/tenant/members/${employee}/status/`, { is_active: status });
  }

  /**
   * Set a member in a tenant to offline.
   *
   * @param token JWT token of authentication.
   * @param tenant Tenant ID of the member.
   * @param member Member ID of the user to set offline.
   */
  public setMemberOffline(token: string, tenant: number, member: number): void {
    fetch(`${this.base}tenants/${tenant}/tenant/members/${member}/set-offline/`, {
      method: 'POST',
      headers: { Authorization: `JWT ${token}` },
      keepalive: true,
    })
      .then((): void => {
        console.debug('[ApiService] setMemberOffline beacon succeeded.');
      })
      .catch((): void => {
        console.debug('[ApiService] setMemberOffline beacon failed to send.');
      });
  }
}
